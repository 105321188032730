import { useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = localStorage.getItem(key);

            if (!item) return initialValue;

            return JSON.parse(item);
        } catch (error) {
            console.log(error);

            return initialValue;
        }
    });

    const setValue = (value: any): void => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;

            if (!value) {
                localStorage.removeItem(key);
                return;
            }

            localStorage.setItem(key, JSON.stringify(valueToStore));

            setStoredValue(valueToStore);
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}
