import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { modularScale } from '../util/modularScale';
import { grey } from '../util/theme';
import { Badge } from 'antd';
import { Button } from '@material-ui/core';
import { CartContext } from './context/CartContext';
import { AuthContext } from './context/AuthContext';

const Container = styled.div`
	display: none;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	
	@media (min-width: 900px) {
        display: flex;
    }
`;

const LinkList = styled.div`
	display: flex;
	align-items: center;
	
	a {
	    line-height: 1;
	}
`;

const ButtonList = styled.div`
	display: flex;
	align-items: center;
`;

const NavLink = styled(Link)`
    color: #000;
    font-size: ${modularScale(1)};
    font-weight: 500;
    margin-right: 1rem;
    
    @media (min-width: 990px) {
        margin-right: 1.5rem;
    }
`;

const NavClick = styled.a`
    color: #000;
    font-size: ${modularScale(1)};
    font-weight: 500;
    margin-right: 1rem;
    
    @media (min-width: 990px) {
        margin-right: 1.5rem;
    }
`

const CartLink = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
	color: #000;
	font-weight: 600;
	border: 2px solid #000;
	padding: 4px;
	width: 100px;
	border-radius: 50px;
	text-align: center;
	margin-right: 0.5rem;
	
	&:focus, &:hover, &:active {
	    color: #000;
	}
	
	svg {
	    width: 24px;
	}
`;

const StyledBadge = styled(Badge)`
	margin-left: 4px;
`;

const SignInLink = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
	color: #fff;
	background-color: ${grey};
	padding: 6px;
	width: 100px;
	border-radius: 50px;
	margin-right: 1rem;
	
	&:focus, &:hover, &:active {
	    color: #fff;
	}
	
	&:hover {
	    background-color: ${grey};
	}
`;

const Nav: React.FC = () => {
    const { cartItems } = useContext(CartContext);
    const { isAuthenticated, logout } = useContext(AuthContext);

    const onLogout = () => {
        logout();
        navigate('/');
    }

    return (
        <Container>
            <LinkList>
                <NavLink to={'/sheets'}>Sheets</NavLink>
                <NavLink to={'/requests'}>Requests</NavLink>
                <NavLink to={'/contact'}>Contact</NavLink>
                { isAuthenticated && <NavClick onClick={onLogout}>Logout</NavClick> }
            </LinkList>
            <ButtonList>
                <SignInLink component={Link} to={isAuthenticated ? '/my-products' : '/login'}>
                    {isAuthenticated ? 'My Sheets' : 'Sign In'}
                </SignInLink>
                <CartLink component={Link} to={'/cart'}>Cart <StyledBadge count={cartItems.length} /></CartLink>
            </ButtonList>
        </Container>
    );
};

export default Nav;
