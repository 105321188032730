import React from 'react';
import styled from 'styled-components';
import { grey } from '../util/theme';

const MenuButton = styled.span`
    z-index: 4;
    height: 28px;
    width: 28px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    
    span,
    span::before,
    span::after {
        position: absolute;
        content: '';
        width: 28px;
        height: 3px;
        border-radius: 20px;
        transition: 220ms cubic-bezier(0.15, 1, 0.3, 1);
    }
    
    span  {
        background: ${props => props.isOpen ? 'transparent' : '#000'};
    }
    
    span::before {
        top: ${props => props.isOpen ? '0' : '-8px'};
        background: ${props => props.isOpen ? grey : '#000'};
        transform: ${props => props.isOpen ? 'rotate(-225deg)' : 'rotate(0deg)'};
    }
    
    span::after {
        top: ${props => props.isOpen ? '0' : '8px'};
        background: ${props => props.isOpen ? grey : '#000'};
        transform: ${props => props.isOpen ? 'rotate(225deg)' : 'rotate(0deg)'};
    }
`;

interface Props {
    onClick: () => void;
    isOpen: boolean;
}

const NavMenuButton: React.FC<Props> = ({ onClick, isOpen }) => {
    return (
        <MenuButton onClick={onClick} isOpen={isOpen}><span /></MenuButton>
    );
};

export default NavMenuButton;
