import React, { createContext, useEffect, useState } from 'react';
import { Product, CartProduct } from '../../types/types';
import { useLocalStorage } from '../../util/useLocalStorage';

interface CartContextInterface {
    cartItems: CartProduct[];
    setCartItems: (cartItems: CartProduct[]) => void;

    addProduct: (product: Product) => void;
    removeProduct: (id: string) => void;
    clearCart: () => void;
}

const CartContext = createContext({} as CartContextInterface);

const { Provider, Consumer } = CartContext;

const CartProvider: React.FC = ({ children }) => {
    const [cartItems, setCartItems] = useLocalStorage<CartProduct[]>('cart', []);

    const addProduct = (product: Product): void => {
        const newItems = cartItems.slice();

        newItems.push({
            productId: product.productId,
            title: product.title,
            subtitle: product.subtitle,
            qty: 1,
            unitPrice: product.price
        })

        setCartItems(newItems);
    }

    const removeProduct = (productId: string): void => {
        const newItems = cartItems.filter(i => i.productId !== productId);
        setCartItems(newItems);
    }

    const clearCart = () => {
        setCartItems([]);
    }

    return (
        <Provider
            value={{
                cartItems,
                setCartItems,

                addProduct,
                removeProduct,
                clearCart
            }}
        >
            {children}
        </Provider>
    );
};

export { CartContext, CartProvider, Consumer as CartConsumer };
