import React from 'react';

const Cart: React.FC = (props) => {
    return (
        <svg {...props} viewBox="0 0 29.16 26.969">
            <path id="cart_icon" data-name="cart icon" fill="currentColor" fillRule="evenodd"
                  d="M1844.96,130.957a0.987,0.987,0,0,0,0,1.973h3.23c1.62,4.044,3.2,8.1,4.8,12.147l-1.47,3.587a1.018,1.018,0,0,0,.09.928,0.985,0.985,0,0,0,.81.439h16.22a0.987,0.987,0,1,0,0-1.974h-14.76l0.84-2.035,15.63-1.263a1,1,0,0,0,.87-0.761l1.95-8.55a1.04,1.04,0,0,0-.96-1.2h-21.4l-1.05-2.672a1.01,1.01,0,0,0-.91-0.616h-3.89Zm6.63,5.262h19.4l-1.51,6.638-14.8,1.192Zm4.08,15.127a3.289,3.289,0,1,0,3.24,3.289A3.287,3.287,0,0,0,1855.67,151.346Zm9.73,0a3.289,3.289,0,1,0,3.24,3.289A3.278,3.278,0,0,0,1865.4,151.346Zm-9.73,1.973a1.316,1.316,0,1,1-1.3,1.316A1.285,1.285,0,0,1,1855.67,153.319Zm9.73,0a1.316,1.316,0,1,1-1.3,1.316A1.293,1.293,0,0,1,1865.4,153.319Z"
                  transform="translate(-1844 -130.969)"/>
        </svg>
    );
};

export default Cart;
