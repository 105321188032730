import Icon from '@mdi/react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { mdiClose, mdiMenu } from '@mdi/js';
import { gradient, grey } from '../util/theme';
import { modularScale } from '../util/modularScale';
import { AuthContext } from './context/AuthContext';

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: ${props => props.isOpen ? '0' : '100%'};
	z-index: 3;
	transition: left 220ms cubic-bezier(0.15, 1, 0.3, 1);
`;

const LinkList = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: calc(3rem + 35px);
	font-size: ${modularScale(4)};
    padding: 1rem 1.2rem;
    align-items: flex-end;
`;

const NavLink = styled(Link)`
    font-weight: 500;
	color: ${grey};
	margin-bottom: 0.5rem;
`;

const NavClick = styled.a`
    font-weight: 500;
	color: ${grey};
	margin-bottom: 0.5rem;
`;

const FooterButton = styled(Button)`
	width: 100%;
	background: ${gradient};
	color: #fff;
	padding: 1.2rem;
	margin-top: auto;
	font-size: ${modularScale(1)};
	border-radius: 0;
	
	&:active, &:focus, &:hover {
	    color: #fff;
	}
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const MobileNav: React.FC<Props> = ({ isOpen, onClose }) => {
    const { isAuthenticated, logout } = useContext(AuthContext);

    const onLogout = () => {
        logout();
        navigate('/');
        onClose();
    }

    return (
        <Container isOpen={isOpen}>
            <LinkList>
                <NavLink to={'/sheets'} onClick={onClose}>Sheets</NavLink>
                <NavLink to={'/requests'} onClick={onClose}>Requests</NavLink>
                <NavLink to={'/contact'} onClick={onClose}>Contact</NavLink>
                { isAuthenticated && <NavClick onClick={onLogout}>Logout</NavClick> }
            </LinkList>
            <FooterButton component={Link} to={isAuthenticated ? '/my-products' : '/login'} onClick={onClose}>
                {isAuthenticated ? 'My Sheets' : 'Sign In'}
            </FooterButton>
        </Container>
    );
};

export default MobileNav;
