import React from 'react';
import Debug from '../Debug';
import SEO from '../seo/SEO';
import Header from "../Header";
import Footer from "../Footer";
import styled from 'styled-components';
import { AuthProvider } from "../context/AuthContext";
import { CartProvider } from '../context/CartContext';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 80vh;
`;

interface Props {
    customSEO?: boolean;
}

const Layout: React.FC<Props> = ({customSEO, children}) => {
    return (
        <AuthProvider>
            <CartProvider>
                {!customSEO && <SEO />}
                <Container>
                    <Header />
                    <Content>
                        {children}
                    </Content>
                    <Footer />
                </Container>
            </CartProvider>
        </AuthProvider>
    );
};

export default Layout;
