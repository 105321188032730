import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';
import { Badge } from 'antd';
import Cart from './icons/Cart';
import MobileNav from './MobileNav';
import NavMenuButton from './NavMenuButton';
import { Link } from 'gatsby';
import Nav from './Nav';
import { CartContext } from './context/CartContext';
import Banner from './Banner';

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;

    @media (min-width: 900px) {
        padding: 0.5rem;
        margin-bottom: 2rem;
    }
`;

const HomeLink = styled(Link)`
    max-width: 60%;
    width: 280px;
`;

const StyledCart = styled(Cart)`
    width: 30px;
`;

const StyledLink = styled(Link)`
    color: #000;

    &:hover, &:focus, &:active {
        color: #000;
    }
`;

const MobileIcons = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (min-width: 900px) {
        display: none;
    }
`;

const Header: React.FC = () => {
    const { cartItems } = useContext(CartContext);
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {/*<Banner />*/}
            <Container>
                <HomeLink to={'/'}><Logo/></HomeLink>
                <Nav/>
                <MobileIcons>
                    <Badge count={cartItems.length}>
                        <StyledLink to={'/cart'}><StyledCart/></StyledLink>
                    </Badge>
                    <NavMenuButton onClick={toggleMenu} isOpen={isOpen}/>
                </MobileIcons>
                <MobileNav isOpen={isOpen} onClose={() => setIsOpen(false)}/>
            </Container>
        </>
    );
};

export default Header;
