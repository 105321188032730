import React from 'react';
import styled from 'styled-components';
import { grey, navy } from '../util/theme';
import Icon from '@mdi/react';
import { mdiFacebook, mdiInstagram, mdiTwitter, mdiYoutube } from '@mdi/js';
import { Link } from 'gatsby';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${grey};
	color: #fff;
	padding: 3rem;
`;

const Socials = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	
	svg {
		color: #fff;
	    width: 24px;
	    margin: 0 0.25rem;
	}
`;

const Legal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;
	
	a {
	    color: #fff;
	}
	
	@media (min-width: 900px) {
	    flex-direction: row;
	}
`;

const Copyright = styled.div`
	
`;

const Divider = styled.div`
    display: none;
	
	@media (min-width: 900px) {
	    display: block;
        margin: 0 0.5rem;
	}
`;

const Footer: React.FC = () => {
    return (
        <Container>
            <Socials>
                <a href='https://www.youtube.com/c/pauliano' target="_blank"><Icon path={mdiYoutube} /></a>
                <a href='https://www.facebook.com/paulianomusic' target="_blank"><Icon path={mdiFacebook} /></a>
                <a href='https://www.instagram.com/paulianomusic' target="_blank"><Icon path={mdiInstagram} /></a>
                <a href='https://www.twitter.com/paulianomusic' target="_blank"><Icon path={mdiTwitter} /></a>
            </Socials>
            <Legal>
                <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
                <Divider>|</Divider>
                <Link to={'/privacy-policy'}>Privacy Policy</Link>
            </Legal>
            <Copyright>
                &#169; Pauliano {new Date().getFullYear()}
            </Copyright>
        </Container>
    );
};

export default Footer;
